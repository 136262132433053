import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"
import moment from "moment"

import Seo from "../components/seo"
import Localize from "../components/localize"
import Logo from "../images/haackshubert_logo.png"
import { NoData } from "../svgs/svg"

const Publications = ({
  data: { publications, site },
  pageContext,
  location,
}) => {
  const lan = pageContext.locale
  const allPublications = publications.edges
  const filteredPublications = []
  const [filter, setFilter] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [publicationss, setNews] = useState(publications.edges)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    if (location.state && location.state.filter) {
      onChangeFilter(location.state.filter)
    } else {
      onChangeFilter(filter)
    }
    setTimeout(() => {
      if (location.state && location.state.scroll) {
        window.scrollTo({
          top: location.state.scroll,
          left: 0,
          behavior: "smooth",
        })
      }
    })
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true })
    }
  }, [location.state])

  const handleScroll = () => {
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    setScrollPosition(top)
  }

  const onChangeFilter = filt => {
    if (filter === filt) {
      setFilter("")
      setNews(allPublications)
    } else {
      setFilter(filt)
      filt &&
        allPublications.forEach(ne => {
          ne.node.tag.forEach(ta => {
            if (ta.title === filt) {
              filteredPublications.push(ne)
            }
          })
        })
      window.scroll({
        top: 0,
        behavior: "smooth",
      })
      setNews(filteredPublications)
    }
  }

  const handleChange = event => {
    setError("")
    setEmail(event.target.value)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const result = await addToMailchimp(email)
    if (result.result === "error") {
      if (result.msg === "The email you entered is not valid.") {
        setError(
          lan === "en"
            ? "The email you entered is not valid"
            : "Die eingegebene E-Mail ist ungültig"
        )
      } else {
        setError(
          lan === "en"
            ? "You have already subscribed."
            : "Sie haben sich bereits abonniert."
        )
      }
      setLoading(false)
    } else {
      setEmail("")
      setError("")
      setLoading(false)
      alert("You have been subscribed!")
    }
  }
  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "Publications" : "Publikationen"}
        description={
          lan === "en"
            ? "Always stay informed. Here you will find everything you need to know about: legal advice, notaries, tax advice, auditing, real estate and business advice."
            : "Bleiben Sie immer informiert. Hier finden Sie alles Wissenswerte zu: Rechtsberatung, Notare, Steuerberatung, Wirtschaftsprüfung, Immobilien- & Unternehmensberatung."
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "Publications: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Publikationen: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <div className="newsletter-form no--margin-menu padding--menu-sw">
        <span>
          {lan &&
            site.siteMetadata &&
            site.siteMetadata[lan].subscribeToOurNewsletter}
          :
        </span>
        <div className="d-flex flex-direction-column height-80">
          <input
            className="newsletter-input"
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="Email"
          />
          <span className="color-red">{error}</span>
        </div>

        <button
          className={`${
            loading ? "cursor-not-allowed bg-grey" : ""
          } news-submit-button ml--15 mt-20`}
          onClick={loading ? () => {} : handleSubmit}
        >
          {lan && site.siteMetadata && site.siteMetadata[lan].subscribe}
        </button>
      </div>
      <div className="news-wrapper-div">
        <div id="news" className="news-box">
          <div className="w-100">
            <h1 className="heading-text">
              {lan === "en" ? "Publications" : "Publikationen"}
            </h1>
            <ul className="ne-wrapper">
              {publicationss.length > 0 ? (
                publicationss.map((ne, index) => {
                  return (
                    <div key={index + 1} className="news-list">
                      <Link
                        className="no-link-hover"
                        to={`/${pageContext.locale}/publications/${ne.node.slug.current}/`}
                        state={{ scroll: scrollPosition, filter: filter }}
                      >
                        <img
                          src={
                            ne.node.image
                              ? ne.node.image.asset
                                ? `${ne.node.image.asset.url}?h=300`
                                : Logo
                              : Logo
                          }
                          loading="lazy"
                          alt="img"
                          className={`news-image ${
                            ne.node.image ? "" : "object-fit-contain"
                          }`}
                          height={135}
                          width={135}
                        />
                      </Link>

                      <li className="news-li" data-id="2">
                        <div className="content-container">
                          <div className="content">
                            <div className="d-flex">
                              <div style={{ width: "200px", minWidth: "95px" }}>
                                <span>
                                  News{" "}
                                  <span className="ml--5">
                                    &bull;
                                    {moment(ne.node.publishedAt)
                                      .local()
                                      .format("YYYY MMMM, DD")}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <h4>
                              <Link
                                className="no-link-hover w-auto m-auto"
                                state={{
                                  scroll: scrollPosition,
                                  filter: filter,
                                }}
                                to={`/${pageContext.locale}/publications/${ne.node.slug.current}/`}
                              >
                                <h1 className="sen-dot hyphens">
                                  {hyphenated(ne.node.headline, {
                                    language: de,
                                  })}
                                </h1>
                              </Link>
                            </h4>
                            <p className="text-align-left overflow-hidden height-53 ws-3">
                              {ne.node.subDescription}
                            </p>
                          </div>
                        </div>
                        <div className="news-wrapper">
                          {ne.node.tag.map((ta, index) => {
                            return (
                              <button
                                key={index}
                                className={
                                  filter === ta.title
                                    ? "tag-button selected-tag"
                                    : "tag-button"
                                }
                                onClick={() => onChangeFilter(ta.title)}
                              >
                                {ta.title}
                              </button>
                            )
                          })}
                        </div>
                      </li>
                    </div>
                  )
                })
              ) : (
                <div className="d-flex flex-direction-column">
                  <NoData />
                  <div className="content no-data-found">
                    <h2 className="no-data-text">
                      {lan === "en"
                        ? "Oops! No publications found"
                        : "Hoppla! Keine Veröffentlichungen gefunden"}
                    </h2>
                    <span>
                      Please{" "}
                      <Link
                        to={`/${lan ? lan : "de"}/`}
                        style={{ color: "#E40036" }}
                      >
                        {lan === "en" ? "go back" : "geh zurück"}{" "}
                      </Link>
                      {lan === "en" ? "to home page" : "zur Startseite"} .
                    </span>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Localize(Publications)

export const query = graphql`
  query($locale: String) {
    site {
      siteMetadata {
        siteUrl
        en {
          subscribeToOurNewsletter
          subscribe
        }
        de {
          subscribeToOurNewsletter
          subscribe
        }
      }
    }
    publications: allSanityPublications(
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          image {
            asset {
              url
            }
          }
          tag {
            title {
              _type
              en
              de
            }
          }
          publishedAt(locale: $locale)
          headline {
            _type
            en
            de
          }
          subDescription {
            _type
            en
            de
          }
          slug {
            current
          }
        }
      }
    }
  }
`
